import React from 'react';

import BgImage from '../BgImage';
import Button from '../Button';
import Icon from '../Icon';
import Image from '../Image';
import Logo from '../Logo';
import Text from '../Text';

import { buttonColors } from '../../types/buttonTypes';
import { ICON_TYPE } from '../../types/iconTypes';
import { TEXT_TYPE } from '../../types/textTypes';
import { COLOR_TYPE } from '../../types/colorTypes';

class StyleGuideViewComponents extends React.PureComponent {
  render() {
    return (
      <section className="StyleGuideView--components">
        <h2>Components</h2>
        <div className="StyleGuideView--components-Logo">
          <h3>Logo</h3>
          <Logo />
        </div>
        <div className="StyleGuideView--components-Text">
          <h3>Text</h3>
          <div className="StyleGuideView--components-Text-items">
            <div className="StyleGuideView--components-Text-item">
              <h4>Default</h4>
              <Text value="hello" />
            </div>
            <div className="StyleGuideView--components-Text-item">
              <h4>With `hasSplit`</h4>
              <div><Text hasSplit value="hello" /></div>
            </div>
            <div className="StyleGuideView--components-Text-item">
              <h4>With `size`</h4>
              <Text size={TEXT_TYPE.h3} value="hello" />
            </div>
            <div className="StyleGuideView--components-Text-item">
              <h4>With `color`</h4>
              <Text color={COLOR_TYPE.threeColorGradient} value="hello" />
            </div>
          </div>
        </div>
        <div className="StyleGuideView--components-Button">
          <h3>Button</h3>
          {buttonColors.map((color) => (
            <Button
              key={color}
              color={color}
              hasColorBack
              style={{ marginBottom: 16 }}
            >
              {color}
            </Button>
          ))}
        </div>
        <div className="StyleGuideView--components-Icon">
          <h3>Icon</h3>
          <div className="StyleGuideView--components-Icon-items">
            {Object.values(ICON_TYPE).map((type) => (
              <Icon
                key={type}
                type={type}
                style={{ marginRight: 16 }}
              >
                {type}
              </Icon>
            ))}
          </div>
        </div>
        <div className="StyleGuideView--components-Image">
          <h3>Image</h3>
          <Image src="gatsby-astronaut.png" alt="astronaut" style={{ maxWidth: 300 }} />
        </div>
        <div className="StyleGuideView--components-BgImage">
          <h3>BgImage</h3>
          <BgImage url="video-poster-test.jpg" style={{ paddingTop: '25%' }} />
        </div>
      </section>
    );
  }
}

export default StyleGuideViewComponents;
