import PropTypes from 'prop-types';

export const ICON_TYPE = Object.freeze({
  facebook: 'facebook',
  instagram: 'instagram',
  twitter: 'twitter',
  rightArrow: 'rightArrow',
});

const iconTypes = {
  type: PropTypes.oneOf(Object.values(ICON_TYPE)).isRequired,
};

export default iconTypes;
