import React from 'react';

import StyleGuideViewColors from './StyleGuideViewColors';
import StyleGuideViewComponents from './StyleGuideViewComponents';
import StyleGuideViewGrid from './StyleGuideViewGrid';
import StyleGuideViewTypography from './StyleGuideViewTypography';

import './StyleGuideView.scss';

class StyleGuideView extends React.PureComponent {
  render() {
    return (
      <article className="StyleGuideView">
        <div className="container">
          <h1>StyleGuide</h1>
          <StyleGuideViewColors />
          <StyleGuideViewTypography />
          <StyleGuideViewGrid />
          <StyleGuideViewComponents />
        </div>
      </article>
    );
  }
}

export default StyleGuideView;
