import React from 'react';

import withMediaTypes from '../../types/withMediaTypes';
import withMedia from '../../hoc/withMedia';

class StyleGuideViewGrid extends React.PureComponent {
  static propTypes = withMediaTypes;

  render() {
    const { isMobileView } = this.props;
    const gridItems = Array(isMobileView ? 6 : 12).fill().map((item, index) => index);
    const grid3BlockItems = Array(isMobileView ? 2 : 3).fill().map((item, index) => `3block-${index}`);
    return (
      <section className="StyleGuideView--grid">
        <h2>Grid</h2>
        <div className="StyleGuideView--grid-items grid">
          {gridItems.map(index => (
            <div key={index} className="StyleGuideView--grid-item">
              <span className="StyleGuideView--grid-item-index">{index + 1}</span>
            </div>
          ))}
        </div>
        <h3>Full</h3>
        <div className="StyleGuideView--grid-items grid">
          <div className="grid-item-full" />
        </div>
        <h3>8 Columns Center</h3>
        <div className="StyleGuideView--grid-items grid">
          <div className="grid-item-8-center" />
        </div>
        <h3>6 Columns Center</h3>
        <div className="StyleGuideView--grid-items grid">
          <div className="grid-item-6-center" />
        </div>
        <h3>4 Columns right</h3>
        <div className="StyleGuideView--grid-items grid">
          <div className="grid-item-4-right" />
        </div>
        <h3>3 Block</h3>
        <div className="StyleGuideView--grid-items grid">
          {grid3BlockItems.map(key => <div key={key} className="grid-item-3-block" />)}
        </div>
      </section>
    );
  }
}

export default withMedia(StyleGuideViewGrid);
