import React from 'react';

import Layout from '../components/Layout';
import Seo from '../components/SEO';
import StyleGuideView from '../components/StyleGuideView';

import '../styles/global.scss';

const StyleGuidePage = () => (
  <Layout>
    <Seo title="Style Guide" />
    <StyleGuideView />
  </Layout>
);

export default StyleGuidePage;
