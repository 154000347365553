import React from 'react';

import BgImage from '../BgImage';

const body = 'The quick brown fox jumps over the lazy dog';

class StyleGuideViewTypography extends React.PureComponent {
  render() {
    return (
      <section className="StyleGuideView--typography">
        <h2>Typography</h2>
        <div className="StyleGuideView--typography-items">
          <h3>Header 1 (theme--dark)</h3>
          <div className="text--h1 theme--dark">
            <BgImage url="video-poster-test.jpg">Select Better.</BgImage>
          </div>
        </div>
        <div className="StyleGuideView--typography-items">
          <h3>Header 2</h3>
          <div className="text--h2">It's always better to check.</div>
          <h3>Header 2 (theme--dark)</h3>
          <div className="text--h2 theme--dark">
            <BgImage url="video-poster-test.jpg">Experience Better.</BgImage>
          </div>
          <h3>Header 3 (full-color gradient)</h3>
          <div className="text--h3--full-color-gradient">Are you 21 or over?</div>
          <h3>Header 3 (three-color gradient)</h3>
          <div className="text--h3--three-color-gradient">Better is Coming Soon.</div>
        </div>
        <div className="StyleGuideView--typography-items">
          <h3>Header 4</h3>
          <div className="text--h4">{body}</div>
          <h3>Header 4 (theme--dark)</h3>
          <div className="text--h4 theme--dark">
            <BgImage url="video-poster-test.jpg">{body}</BgImage>
          </div>
        </div>
        <div className="StyleGuideView--typography-items">
          <h3>Body</h3>
          <div className="text--body">{body}</div>
          <h3>Body (theme--dark)</h3>
          <div className="text--body theme--dark">
            <BgImage url="video-poster-test.jpg">{body}</BgImage>
          </div>
        </div>
        <div className="StyleGuideView--typography-items">
          <h3>Legal</h3>
          <div className="text--legal">{body}</div>
        </div>
      </section>
    );
  }
}

export default StyleGuideViewTypography;
