import React from 'react';

import { COLOR_TYPE } from '../../types/colorTypes';

class StyleGuideViewColors extends React.PureComponent {
  render() {
    return (
      <section className="StyleGuideView--colors">
        <h2>Colors</h2>
        <div className="StyleGuideView--colors-items">
          {Object.values(COLOR_TYPE).map(color => (
            <div
              key={color}
              className={`StyleGuideView--colors-item ${color}`}
            >
              <div
                className="StyleGuideView--colors-color-pallete"
                style={{
                  background: `var(--${color})`,
                  border: color === 'white' ? 'solid 1px var(--grey)' : 'none',
                }}
              />
              <div className="StyleGuideView--colors-name">
                {color}
              </div>
            </div>
          ))}
          {[COLOR_TYPE.text].map(color => (
            <div
              key={`dark-${color}`}
              className={`StyleGuideView--colors-item ${color} theme--dark`}
              style={{ background: 'var(--grey)' }}
            >
              <div
                className="StyleGuideView--colors-color-pallete"
                style={{
                  background: `var(--${color})`,
                }}
              />
              <div className="StyleGuideView--colors-name">
                {`${color} (theme--dark)`}
              </div>
            </div>
          ))}
        </div>
      </section>
    );
  }
}

export default StyleGuideViewColors;
